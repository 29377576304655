<script>
import BaseLisitng from '../../../../share/ListingWithEditMode'
import clientObjectMixin from '../../../share/mixins/clientObjectMixin'

export default {
  mixins: [
    BaseLisitng,
    clientObjectMixin
  ],
  data () {
    return {
      service: this.$route.params.service,
      headerTitle: { title: 'Mapa przejść', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_transition',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'name', title: 'Nazwa' },
        { id: 1, data: 'fromState', title: 'Status początkowy' },
        { id: 2, data: 'toState', title: 'Status końcowy' },
        { id: 3, data: 'role', title: 'Rola' },
        { id: 4, data: 'metadata', title: 'Tranzycja BR', searchable: false, sortable: true, render (data) {
            return data && data.appraisalBR ? 'Tak' : ''
          }
        }
      ]
    }
  },
  computed: {
    workflowId () { return  this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].id }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_appraisal_workflow_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:appraisal:transitionEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/${this.workflowId}/transitions` }
    }
  }
}
</script>
